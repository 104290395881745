import React, { ReactNode } from 'react'
import { Row, Space, Col, Typography } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
const { Title, Text } = Typography

export interface HistoryBlockProps {
  company: { text: string; href?: string }
  title?: string
  dateRange: { start: string; end: string }
  description: ReactNode
}

const HistoryBlock: React.FC<HistoryBlockProps> = ({
  company: { text, href },
  title,
  dateRange: { start, end },
  description,
}) => {
  return (
    <>
      <Row>
        <Col>
          {href ? (
            <Space>
              <a href={href} target="_blank">
                <Space>
                  <Text strong>{text}</Text>
                  <LinkOutlined />
                </Space>
              </a>
            </Space>
          ) : (
            <Text strong>{text}</Text>
          )}
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Space>
            {title && <Text type="secondary">{title}</Text>}
            {title && <Text type="secondary">|</Text>}
            <Text type="secondary">
              {start} - {end}
            </Text>
          </Space>
        </Col>
        <Col>{description}</Col>
      </Row>
    </>
  )
}

export default HistoryBlock
