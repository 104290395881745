import { useState, useEffect } from 'react';

function useDetectPrint() {
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  useEffect(() => {
    const printMq = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('print');
    const mqEvent: (l: any) => void = mqList => setIsPrinting(!!mqList.matches);
    if(printMq) printMq.addEventListener("change", mqEvent);
    return () => {
      if(printMq) printMq.removeEventListener("change", mqEvent)
    };
  });

  return isPrinting;
}

export default useDetectPrint;