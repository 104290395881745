import { Row, Typography, Space, Divider, Grid, Col, message } from 'antd'
import {
  GithubFilled,
  MailOutlined,
  PhoneOutlined,
  LinkedinOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import React from 'react'
import Container from '../components/container'
import SEO from '../components/seo'
import HistoryBlock from '../components/HistoryBlock'
import SkillBlock from '../components/SkillBlock'
const { Title, Text } = Typography
const { useBreakpoint } = Grid

const IndexPage: React.FC = () => {
  const screen = useBreakpoint()
  console.log(screen)

  return (
    <Container>
      <SEO title="Stephen Gibson - Full-Stack Developer" />
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Row>
            <Title level={1} style={{ marginBottom: 0 }}>
              Stephen Gibson
            </Title>
          </Row>
          <Row justify="space-between">
            <Col>
              <Title level={5} type="secondary">
                Full-Stack Developer
              </Title>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Divider type="horizontal" style={{ marginTop: 0 }} />
        </Col>
        <Col>
          <Text>
            Highly motivated individual with an eye for detail and the drive to
            learn and set challenging goals. Excellent team collaboration skills
            following agile methodologies that allow working in a fast paced
            environment. Versed in technologies such as Typescript, React and
            AWS Cloud Platform with a strong focus on utilising these tools to
            transform designs into solutions.
          </Text>
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Divider type="horizontal" />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col sm={{ span: 24 }} md={{ span: 10 }}>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Title level={4}>Contact</Title>
            </Col>
            <Col span={24}>
              <Space>
                <MailOutlined />
                <Text
                  copyable={{
                    onCopy: () => {
                      message.success('Email copied')
                    },
                    tooltips: false,
                  }}
                >
                  stephen@brawcode.com
                </Text>
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <PhoneOutlined />
                <Text
                  copyable={{
                    onCopy: () => {
                      message.success('Mobile number copied')
                    },
                    tooltips: false,
                  }}
                >
                  07432527181
                </Text>
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <a
                  href="https://www.linkedin.com/in/stephen-gibson3771/"
                  target="_blank"
                >
                  <Space>
                    <LinkedinOutlined style={{ color: '#4d4d4d' }} />
                    <Text>stephen-gibson3771</Text>
                    <LinkOutlined />
                  </Space>
                </a>
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <a href="https://github.com/Gibbo3771" target="_blank">
                  <Space>
                    <GithubFilled style={{ color: '#4d4d4d' }} />
                    <Text>gibbo3771</Text>
                    <LinkOutlined />
                  </Space>
                </a>
              </Space>
            </Col>
          </Row>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Title level={5} style={{ marginTop: '2em' }}>
                Skills
              </Title>
            </Col>
            <Col span={24}>
              <SkillBlock name="JavaScript (ES5, ES6+)" level={4} />
            </Col>
            <Col span={24}>
              <SkillBlock name="TypeScript" level={3} />
            </Col>
            <Col span={24}>
              <SkillBlock
                name="React (NextJS, CRA, Gatsby)"
                level={3}
                tooltip={{
                  overlayStyle: { width: 300 },
                  placement: 'topRight',
                  content: (
                    <Text>
                      I exclusively develop in NextJS to allow fast prototypying
                      and reduction in boiler plate. This allows me to design,
                      develop and work in a highly agile fashion
                    </Text>
                  ),
                }}
              />
            </Col>
            <Col span={24}>
              <SkillBlock
                name="NodeJS"
                level={4}
                tooltip={{
                  overlayStyle: { width: 300 },
                  placement: 'topRight',
                  content: (
                    <Text>
                      Extensive experience in NodeJS to develop RESTful servers
                      using ExpressJS, command line tools using
                      commander/inquirer and develop serverless functions
                    </Text>
                  ),
                }}
              />
            </Col>
            <Col span={24}>
              <SkillBlock name="CSS/LESS/SASS" level={2} />
            </Col>
            <Col span={24}>
              <SkillBlock
                name="AWS"
                level={5}
                tooltip={{
                  overlayStyle: { width: 300 },
                  placement: 'topRight',
                  content: (
                    <Text>
                      I have been working with AWS for around 4 years, 
                      using services such as Lambda, S3, DynamoDB, SNS, EC2 and many others.
                    </Text>
                  ),
                }}
              />
            </Col>
            <Col span={24}>
              <SkillBlock name="GCP" level={4} />
            </Col>
            <Col span={24}>
              <SkillBlock name="CI/CD (CircleCI, Github Actions)" level={4} />
            </Col>
            <Col span={24}>
              <SkillBlock
                name="Build Tools (Webpack, Parcel, Gulp)"
                level={3}
                tooltip={{
                  overlayStyle: { width: 300 },
                  placement: 'topRight',
                  content: (
                    <Text>
                      Use of Webpack is rampant in the industry, that's why I
                      have taken the time to learn (and continue to do so) to
                      use the most popular tool available.
                    </Text>
                  ),
                }}
              />
            </Col>
            <Col span={24}>
              <SkillBlock
                name="Ruby (RoR/POR)"
                level={3}
                tooltip={{
                  overlayStyle: { width: 300 },
                  placement: 'topRight',
                  content: (
                    <Text>
                      Ruby is a beautiful language with a well rounded standard
                      library, strong community and package eco systems. It also
                      powers one of my favoriute MVC frameworks, Ruby on Rails.
                      I tend to fall back onto Ruby on Rails if the project
                      specification calls for it.
                    </Text>
                  ),
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <Row>
            <Col>
              <Row>
                <Title level={4} style={{ marginBottom: 8 }}>
                  Employment
                </Title>
              </Row>
              <Row gutter={[0, 48]}>
              <Col>
                  <HistoryBlock
                    company={{
                      text: 'Geome Ltd',
                      href: 'https://www.geo.me/',
                    }}
                    title="Platform Engineer"
                    dateRange={{ start: '2021', end: 'Present' }}
                    description={
                      <Space direction="vertical" size="large">
                        <Text>
                          At Geome I adopted a hybrid role that was split between
                          the backend Ruby on Rails team and the DevOps team. I spent the first 3 months
                          moving projects from a centralized AWS account to a more robust and secure account per-client
                          architecture. Once complete, I was put onto the new Platform API project. These were APIs for geo location
                          services, a staple of the Geome product.
                        </Text>
                        <Text>
                          I finally moved over to a full DevOps role in my second year at Geome and my primary responsibility
                          thus far as been security improvements and observability. Since we utilize mostly AWS, my day to day
                          is working with Cloudformation to provision several AWS products/services, a couple to name are EC2 and Cloudfronts.
                          Coding and infrastructure aside I have also taken it upon myself to write up and maintain security policies, these
                          include items such as Threat Models and Incident Manangement.
                        </Text>
                      </Space>
                    }
                  />
                </Col>
                <Col>
                  <HistoryBlock
                    company={{
                      text: 'Semestry Ltd',
                      href: 'https://semestry.com',
                    }}
                    title="Front-end Developer"
                    dateRange={{ start: '2021', end: '2021' }}
                    description={
                      <Space direction="vertical" size="large">
                        <Text>
                          Working closely with the Team Lead to architect,
                          design and develop a new and improved version of their
                          cutting edge timetabling software using modern
                          approaches. I found myself quickly taking a leadership role overseeing
                          the international development team, with this I introduced several
                          DX improvements and processes.
                        </Text>
                      </Space>
                    }
                  />
                </Col>
                <Col>
                  <HistoryBlock
                    company={{
                      text: 'Duradiamond Software',
                      href: 'https://www.linkedin.com/company/duradiamond-software-limited/',
                    }}
                    title="Full-Stack Developer"
                    dateRange={{ start: '2019', end: '2021' }}
                    description={
                      <Space direction="vertical" size="large">
                        <Text>
                          My role here was to develop a cloud solution to
                          replace the legacy PHP user management system. While
                          here I implemented federated authentication, a B2B
                          dashboard and payment provider integration using
                          Stripe. Achieved by using React for the dashboard and
                          a microservice event driven architecture using
                          Lambdas, API gateway, S3, SNS, Cloudfront and various
                          other AWS product services for the backend.
                        </Text>
                        <Text>
                          As well as the company's own products, I developed
                          several projects for 3rd party companies using Ruby on
                          Rails. These consisted of white labeled dashboards
                          that consume our APIs to the client specification.
                        </Text>
                      </Space>
                    }
                  />
                </Col>
                <Col>
                  <HistoryBlock
                    company={{ text: 'Delta DNA' }}
                    title="Intern"
                    dateRange={{ start: '2015', end: '2016' }}
                    description={
                      <Space direction="vertical" size="large">
                        <Text>
                          As part of my HND games development study, I interned
                          at DeltaDNA to develop a match three style game to
                          demo the analytics platform to clients. It was
                          developed in unity and used various game architecture
                          design patterns such as entity component management
                          systems, behaviour trees and a star search
                        </Text>
                      </Space>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Divider />
              </Row>
              <Row style={{ marginTop: 24 }}>
                <Title level={4} style={{ marginBottom: 8 }}>
                  Education
                </Title>
              </Row>
              <Row gutter={[0, 48]}>
                <Col>
                  <HistoryBlock
                    company={{
                      text: 'Edinburgh College',
                      href: 'https://www.edinburghcollege.ac.uk/',
                    }}
                    dateRange={{ start: '2014', end: '2016' }}
                    description={
                      <Space direction="vertical" size="large">
                        <Text>HND No Degree</Text>
                      </Space>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Divider />
              </Row>
              <Row style={{ marginTop: 24 }}>
                <Col>
                  <Title level={5}>Certifications</Title>
                  <Row style={{ marginBottom: 8 }} gutter={[0, 8]}>
                    <Col span={24}>
                      <Text>
                        During my 4 years as a mechanic, I sought out
                        professional certification and acquired the following:
                      </Text>
                    </Col>
                    <Col span={24}>
                      <a
                        href="https://www.cytech.training/courses/technical-one/"
                        target="_blank"
                      >
                        <Space>
                          <Text>Cytech Level 1</Text>
                          <LinkOutlined />
                        </Space>
                      </a>
                    </Col>
                    <Col span={24}>
                      <a
                        href="https://www.cytech.training/courses/technical-two/"
                        target="_blank"
                      >
                        <Space>
                          <Text>Cytech Level 2</Text>
                          <LinkOutlined />
                        </Space>
                      </a>
                    </Col>
                    <Col span={24}>
                      <a
                        href="https://www.cytech.training/courses/technical-three/"
                        target="_blank"
                      >
                        <Space>
                          <Text>Cytech Level 3</Text>
                          <LinkOutlined />
                        </Space>
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: 32 }}>
        <Col>
          <Text strong>References available on request</Text>
        </Col>
      </Row>
    </Container>
  )
}

export default IndexPage
