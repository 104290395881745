import React, { ReactElement } from 'react'
import { Space, Typography, Popover, PopoverProps } from 'antd'
import { StarFilled, StarOutlined, InfoCircleOutlined } from '@ant-design/icons'
import useDetectPrint from './useDetectPrint'
const { Text } = Typography

export interface SkillBlockProps {
  level: 1 | 2 | 3 | 4 | 5
  name: string
  tooltip?: PopoverProps
}

const SkillBlock: React.FC<SkillBlockProps> = ({ name, level, tooltip }) => {
  const isPrinting = useDetectPrint();
  const stars: ReactElement[] = []
  for (let i = 0; i < 5; i++)
    stars.push(
      i < level ? <StarFilled style={{ color: '#11a71e' }} /> : <StarOutlined />
    )
  return (
    <Space direction="vertical" size="small">
      {tooltip && !isPrinting ? (
        <Popover {...tooltip}>
          <Space style={{ cursor: 'pointer' }}>
            <Text strong>{name}</Text>
            <InfoCircleOutlined style={{ color: '#34b7ce' }} />
          </Space>
        </Popover>
      ) : (
        <Text strong>{name}</Text>
      )}

      <Space>{stars}</Space>
    </Space>
  )
}

export default SkillBlock
